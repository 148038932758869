// cookieManager.js
import Cookies from "js-cookie";
import {EventEmitter} from "events";
import cookie from "cookie";

const cookieEventEmitter = new EventEmitter();

export const setCookie = (name, value, options) => {
  Cookies.set(name, value, options);
  cookieEventEmitter.emit("cookieChange", { name, value });
};

export const removeCookie = (name, options) => {
  Cookies.remove(name, options);
  cookieEventEmitter.emit("cookieChange", { name, value: null });
};

export const getCookie = (name) => {
    return Cookies.get(name);
};

export const getRequestCookie = (request, name) => {
    const cookies = cookie.parse(request?.headers?.get("cookie") ?? "");
    return cookies[name];
};

export const onCookieChange = (callback) => {
    cookieEventEmitter.on("cookieChange", callback);
};

export const offCookieChange = (callback) => {
    cookieEventEmitter.off("cookieChange", callback);
};
