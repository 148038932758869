import expireReducer from "redux-persist-expire";
import {
  getCookie,
  removeCookie,
  setCookie,
} from "../utils/helpers/cookieManager";
import { SelectOptionProps } from "../components/ui/custom-select";

export const GTM_ID = "GTM-WVBWH8H";
export const DefaultImage = "/defaults/default_place_holder.png";
export const DefaultStoreImage = "/defaults/default-store.webp";
export const DefaultImageUser = "/default-logo-img.jpg";

export enum CookieEnum {
  Token = "matjr_token",
  AllKeys = "matjr_presist",

  DefaultLocation = "use_default_location",
  Location = "location",
  AddressId = "address_id",
  SelectedAddress = "selectedAddress",
  SelectedService = "selectedService",
  SelectedServiceName = "selectedServiceName",
  DistrictId = "district_id",
  Language = "matjr_lang",
  SelectedServiceQuotation = "selectedServiceQuotation",
  SelectedServiceFilterType = "selectedServiceFilterType",
  SelectedServiceDistrictSupport = "selectedServiceDistrictSupport",
  OfflineCartKey = "uuid",
}

export const persistConfig = {
  key: CookieEnum.AllKeys,
  keyPrefix: "", // Prevent redux-persist from adding "persist:" as a prefix
  storage: {
    getItem: (key: any) => Promise.resolve(getCookie(key)),
    setItem: (key: any, value: any) =>
      Promise.resolve(setCookie(key, value, { expires: 1 })),
    removeItem: (key: any) => Promise.resolve(removeCookie(key)),
  },
  whitelist: ["User", "auth", "cart"],
  transforms: [
    expireReducer("User", {
      expireSeconds: 10,
      expiredState: {},
    }),
  ],
};

export const PerPageOptions = [
  {
    id: 10,
    count: 10,
    name: "10",
  },
  {
    id: 15,
    count: 15,
    name: "15",
  },
  {
    id: 25,
    count: 25,
    name: "25",
  },
  {
    id: 50,
    count: 50,
    name: "50",
  },
];

export const DefaultLimit = 50;
export const DEFAULT_PHONE_CODE = "+974";
export const QATAR_ISO_CODE = "QA";


export const NOT_FOUND_CODE = 410;
